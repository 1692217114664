import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_BUSINESS, UPDATE_BUSINESS, DELETE_BUSINESS } from '../gqloperations/mutations';
import BusinessItem from './BusinessItem';
import BusinessForm from './BusinessForm';
import { GET_BUSINESSES, GET_BUSINESS_TYPES } from '../gqloperations/queries';
import { AUTH_TOKEN } from '../constants';
import { getUserIdFromToken } from '../gqloperations/utils';

const BusinessList = () => {
  // Get the user ID from the token stored in local storage
  const userId = getUserIdFromToken(localStorage.getItem(AUTH_TOKEN));

  // Queries and mutations
  const { loading: typesLoading, error: typesError, data: typesData } = useQuery(GET_BUSINESS_TYPES);
  const [createBusiness] = useMutation(CREATE_BUSINESS);
  const [updateBusiness] = useMutation(UPDATE_BUSINESS);
  const [deleteBusiness] = useMutation(DELETE_BUSINESS);

  const { loading: businessesLoading, error: businessesError, data: businessesData, refetch: refetchBusinesses } = useQuery(GET_BUSINESSES,
    {
      context: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` // Agregar el token JWT como encabezado de autorización
        }
      },
      variables: { owner: userId }
    });

  // Handlers
  const handleCreate = async (name, businessType) => {
    console.log(businessType)
    if (userId) {
      await createBusiness({ variables: { name, businessType, owner: userId } });
      refetchBusinesses()
      console.log(businessesData.businesses)
    } else {
      console.error('User ID not found in token');
    }
  };

  const handleUpdate = async (id, name, businessType) => {
    await updateBusiness({ variables: { id, name, businessType } });
  };

  const handleDelete = async (id) => {
    await deleteBusiness({ variables: { id } });
    refetchBusinesses()
  };

  console.log(typesData)

  if (businessesLoading || typesLoading) return <p>Loading...</p>;
  if (businessesError || typesError) return <p>Error :</p>;

  return (
    <div>
      
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Business Type
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Owner
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {businessesData.businesses.map((business) => (
            <BusinessItem
              key={business.id}
              business={business}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              businessTypes={typesData?.businessTypes}
            />
          ))}
        </tbody>
      </table>

      <BusinessForm onSubmit={handleCreate} businessTypes={typesData?.businessTypes} />
    </div>
  );
};

export default BusinessList;