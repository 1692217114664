import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "../constants";
import { LOGIN_MUTATION, REGISTER_USER } from "../gqloperations/mutations";

const Login = ({ onLogin }) => {
  //The main component of this file,a functional component that manages its own
  //state and behavior.
  const navigate = useNavigate(); //This hook is used to handle navigation within the application.
  const [formState, setFormState] = useState({
    login: true,
    email: '',
    name: '',
    password: '',
    passwordConfirm: '',
  });
  
  // mutation for logging in, in which variables that are derived from the formState
  const [login, { loading, error }] = useMutation(LOGIN_MUTATION, {
    //The useMutation result is a tuple [login, { loading, error }] with a mutate function in the first position  
      //and an object representing the mutation result in the second position.
      //Loading: If true, the mutation is currently in flight.
    //The name of above mutation is login that we can call any time to run this mutation
    //Unlike useQuery, useMutation doesn't execute its operation automatically on render. Instead, 
      //you call this mutate function.
    //In brackets {} { loading, error etc } is an object with fields that represent the current status of 
      //the mutation's execution (data, loading, etc.)
    variables: {
      email: formState.email,
      password: formState.password,
    },
    //callback that handle what happens after the above mutation completes without errors  
    onCompleted: ( login ) => {
      // Store the token in local storage
      localStorage.setItem(AUTH_TOKEN, login.token);
      console.log('Login data:', login);
      //storing the authentication token and
      onLogin(login.tokenAuth.token);
      //navigating to the home page
      navigate("/");
    },
    onError: (error) => {
      console.error('Login error:', error);
    },
  });

  //similar notes as above login mutation
  const [signup] = useMutation(REGISTER_USER, {
    //signup is a method returned from an array that's why enclosed in []
    variables: {
      name: formState.name,
      email: formState.email,
      password1: formState.password,
      password2: formState.passwordConfirm,
    },
    onCompleted: ( signup ) => {
      console.log('Signup data:', signup);
      console.log(signup.createUser.token)
      onLogin(signup.createUser.token);
      navigate("/");
    },
    onError: (error) => {
      console.error('Signup error:', error);
    },
  });

  // Define the handleLogin function
  const handleLogin = async (email, password) => {
    //An asynchronous function is a function that returns a Promise. It allows us to write code that can perform 
    //asynchronous operations, such as fetching data from a server, without blocking the execution of the rest of 
    //our code.
    //Asynchronous operations in programming refer to tasks that are designed to execute independently of 
      //the main program flow. These operations are typically used to perform actions that may take some time 
      //to complete, such as network requests, file I/O, or any other operation that doesn't need to be completed 
      //immediately.
    //A Promise can be in one of three states:
      // Pending: The initial state; neither fulfilled nor rejected.
      // Fulfilled: The operation completed successfully, and the Promise has a resulting value.
      // Rejected: The operation failed, and the Promise has a reason for the failure.
    //The async/await syntax is a more modern and readable way to work with Promises. An async function always 
    //returns a Promise, and the await keyword is used to wait for a Promise to settle and extract its value.
    try {
      await login({ variables: { email, password } }); //Login mutation is called
      //The await keyword is used inside an async function to pause the execution of the function 
      //until the Promise resolves.
      //In the case of the login mutation, you want to wait for the mutation to complete before proceeding 
      //to the next line of code.
      // If we didn't use async and await, the function would continue executing without waiting for the 
      //mutation to finish, which could lead to unexpected behavior.
      
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {" "}
      <h4 className="mb-5 text-blue-600 font-bold">
        {formState.login ? "Login" : "Sign Up"}
      </h4>
      <form 
        onSubmit={(event) => {
          event.preventDefault();
          if (formState.login) {
            handleLogin(formState.email, formState.password);
          } else {
            signup();
          }
        }}
        className="w-full max-w-xs"
      >
        {" "}
        {/* Limit the width of the form */}
        <div className="flex flex-col w-full">
          {!formState.login && (
            <input
              value={formState.name}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value,
                })
              }
              type="text"
              placeholder="Your name"
              className="mb-3 border-2 border-blue-600 rounded-lg p-2"
              autoComplete="username"
            />
          )}
          <input
            value={formState.email}
            onChange={(e) =>
              setFormState({
                ...formState,
                email: e.target.value,
              })
            }
            type="text"
            placeholder="Your email address"
            className="mb-3 border-2 border-blue-600 rounded-lg p-2"
            autoComplete="username"
          />
          <input
            value={formState.password}
            onChange={(e) =>
              setFormState({
                ...formState,
                password: e.target.value,
              })
            }
            type="password"
            placeholder={
              formState.login ? "password" : "Choose a safe password"
            }
            className="mb-3 border-2 border-blue-600 rounded-lg p-2"
            autoComplete="current-password"
          />
          {!formState.login && (
            <input
              value={formState.passwordConfirm}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  passwordConfirm: e.target.value,
                })
              }
              type="password"
              placeholder="Confirm your password"
              className="mb-3 border-2 border-blue-600 rounded-lg p-2"
              autoComplete="new-password"
            />
          )}
        </div>
        <div className="flex mt-3">
          <button
            type="submit"
            className="flex-grow mr-4 px-4 py-2 bg-blue-500 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-lg"
          >
            {formState.login ? "Login" : "Create Account"}
          </button>
          <button
            type="button"
            className="flex-grow ml-4 px-4 py-2 bg-orange-300 text-white hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-lg"
            onClick={(e) =>
              setFormState({
                ...formState,
                login: !formState.login,
              })
            }
          >
            {formState.login ? "Make new account" : "Go to login"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
