import React from 'react'
import { useParams } from 'react-router-dom'

const Business = () => {

  const {id} = useParams()

  return (
    <div>{id}</div>
  )
}

export default Business