import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

// Create an HttpLink instance with the GraphQL endpoint
const httpLink = new HttpLink({
 uri: process.env.REACT_APP_BACK_URL, // Make sure to include the trailing slash //we can use .env
});

// Create an ApolloClient instance with the HttpLink and cache configuration
const client = new ApolloClient({
 link: httpLink,
 cache: new InMemoryCache(),
});

export default client;