import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloProvider, ApolloClient, InMemoryCache} from '@apollo/client'
import client from './apolloClient';
//The Apollo Client instance is an object that manages the communication between your React application and 
//your GraphQL server. It provides methods for sending queries and mutations, caching query results, 
//and managing local state.

const root = ReactDOM.createRoot(document.getElementById('root'));//new React 18 API to create a root for 
              //React application. The ReactDOM.createRoot method is part of the new React 18 concurrent mode, 
              //which allows React to work on multiple tasks at once without blocking the main thread.
              //This means that React can start rendering updates, even if there are still updates from previous 
              //renders that haven't finished yet.
// After you’ve created a root, you need to call root.render to display a React component inside of it
root.render(//render method is used to render a React element into the DOM
  //By wrapping your root component with ApolloProvider, you're making the Apollo Client instance available to 
  //all components in your application through React's context system to perform GraphQL operations without having 
  //to pass it down through props.
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
  //ApolloProvider is a React component that provides the Apollo Client instance to the rest of application 
  //through React's context system. This is necessary because GraphQL operations are typically performed in 
  //various parts of your application, and having a single, shared instance of ApolloClient simplifies the 
  //management of these operations. It's specifically designed for this purpose, however, we could manually 
  //pass the client instance down through props or use a state management library that supports context to
  // achieve a similar effect. But using ApolloProvider is the standard and recommended way to provide the 
  //Apollo Client instance in a React application.
);
