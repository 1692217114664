import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import LandingNav from './components/LandingNav';
import Login from './pages/Login';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import 'tailwindcss/tailwind.css';
import { AUTH_TOKEN } from './constants';
import Business from './pages/Business';
import BusinessNav from './components/BusinessNav';

const App = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem(AUTH_TOKEN));

  const handleLogin = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
    setAuthToken(token);//This updates the authToken state variable with the new token. This will cause the 
                      //component to re-render with the new token.
  };

  const handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    setAuthToken(null);
  };

  return (
    <div className="center w85">
      <div className="ph3 pv1 background-gray">
        <Router>
          <div className="App">
            
            <Routes>
              <Route element={<LandingNav authToken={authToken} onLogout={handleLogout} />}>
                <Route path="" element={<HomePage />} />
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
              </Route>
              
              {/*This defines a route for the /login path that renders the Login component and passes the 
              handleLogin function as a prop.*/}
              <Route element={<BusinessNav />}>
                <Route path="/business/:id" element={<Business />} />
              </Route>
            </Routes>
          </div>
        </Router>
      </div>
    </div>
  );
  //Managing authentication state in the App component allows you to have a centralized place to handle login and 
  //logout actions. This state can then be passed down to other components that need to know whether the user is 
  //authenticated or not, such as navigation bars, protected routes, or components that display user-specific 
  //data. By storing the token in localStorage, the user's login state is persisted across browser sessions, so 
  //they don't have to log in again when they refresh the page or close and reopen the browser.

  //The App component also provides functions to update this state, such as handleLogin and handleLogout, which are 
  //used to set or remove the token.
};

export default App;