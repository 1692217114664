import { gql } from "@apollo/client";

export const REGISTER_USER = gql`
    mutation RegisterUser(
      $email: String!
      $name: String! 
      $password1: String! 
      $password2: String!
    ) {
      createUser(
        email: $email,
        username: $name,
        password1: $password1,
        password2: $password2
        ) {
          user {
            id
            username
            email
          }
          ##success That return is not defined in the mutation
          ##errors
          token
          refreshToken
        }
      }
`;


export const LOGIN_MUTATION = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    # Change here
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;

export const CREATE_BUSINESS = gql`
  mutation CreateBusiness($name: String!, $businessType: String!, $owner: ID!) {
    createBusiness(name: $name, businessType: $businessType, owner: $owner) {
      id
      name
      businessType
      owner
    }
  }
`;


export const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness($id: ID!, $name: String!, $businessType: String!) {
    updateBusiness(id: $id, name: $name, businessType: $businessType) {
      ok
      business {
        id
        name
        businessType
      }
    }
  }
`;

export const DELETE_BUSINESS = gql`
  mutation DeleteBusiness($id: ID!) {
    deleteBusiness(id: $id) {
      ok
    }
  }
`;