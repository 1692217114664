import { gql } from "@apollo/client";
export const GET_ALL_USERS = gql`
    query getAllUsers{
        users{
            id
            username
        }
    }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      id
      username
      email
      firstName
      lastName
    }
  }
`;

export const GET_BUSINESSES = gql`
  query GetBusinesses($owner: ID!) {
    businesses(owner: $owner) {
      id
      name
      businessType
      owner {
        id
        username
        email
      }
    }
  }
`;

export const GET_BUSINESS_TYPES = gql`
  query {
    businessTypes
  }
`;